import React from 'react'
import hand from '../assets/images/Hellohand.png'

const Banner = () => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="content">
                <h1>Hi, I'm Mary 
                <img src={hand} className="hand"/>
                </h1>
            </header>
            <div className="bannerContent">
                <p>
                I'm passionate about building the future. I like designing & developing digital 
                <br/>experiences that are inclusive, sustainable and human centered.
                <br/>
                <br/>I am currently working on <span style={{color: '#F95D67'}}>Shop-X</span>.
                <br/>Check out my other projects below</p>
            </div>
        </div>
    </section>
)

export default Banner
