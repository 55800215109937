import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import Aos from 'aos'
import 'aos/dist/aos.css'

import pic01 from '../assets/images/screens1.png'

import cade from '../assets/images/cade_screen.png'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
// import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

import pic04 from '../assets/images/netflix-cover.jpg'



const HomeIndex = () => {

    useEffect(()=>{
        Aos.init({ duration: 800 })
      }, [])

    // render() {
        return (
            <Layout>
                <Helmet
                    title="Mary Ojo"
                    meta={[
                        { name: 'description', content: 'My Portfolio' },
                        { name: 'keywords', content: 'sample, something' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                    <article data-aos="fade-up" style={{backgroundImage: `url(${cade})`}}>
                            <header className="major">
                                <h3>Cade</h3>
                                <p>A ML career prediction system</p>
                            </header>
                            <Link to="/cade" className="link primary"></Link>
                        </article>
                        <article data-aos="fade-up"  style={{backgroundImage: `url('https://images.pexels.com/photos/220201/pexels-photo-220201.jpeg')`}}>
                            <header className="major">
                                <h3>Spacia</h3>
                                <p>Up for a space adventure?</p>
                            </header>
                            <Link to="/spacia" className="link primary"></Link>
                        </article>
                        <article data-aos="fade-up" style={{backgroundImage: `url('https://assets.nflxext.com/ffe/siteui/vlv3/c8c8a0ad-86d6-45f1-b21d-821afa4e5027/8fc4213b-7fe2-4821-92d2-78ed5238b14d/NG-en-20220801-popsignuptwoweeks-perspective_alpha_website_small.jpg')`}}>
                            <header className="major">
                                <h3>Netflix Clone</h3>
                                <p>netflix & chill 2</p>
                            </header>
                            <Link to="/netflix-clone" className="link primary"></Link>
                        </article>
                        <article data-aos="fade-up" style={{backgroundImage: `url('https://images.unsplash.com/photo-1573855619003-97b4799dcd8b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80')`}}>
                            <header className="major">
                                <h3>Shop-X</h3>
                                <p><em>Coming Soon</em></p>
                            </header>
                            <Link to="#" className="link primary"></Link>
                        </article>
                       
                        
                       
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Thoughts</h2>
                            </header>
                            <blockquote>"The vast possibilities of our great future will become realities only if we make ourselves responsible for that future." — Gifford Pinchot</blockquote>
                            {/* <ul className="actions">
                                <li><Link to="/landing" className="button next">Get Started</Link></li>
                            </ul> */}
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
// 


export default HomeIndex